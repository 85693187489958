/* libs/explorer/src/Views/user-settings-view/UserSettingsView.scss */
:host mat-card ::ng-deep .wrap .mat-mdc-card-header-text {
  flex-wrap: wrap;
}
:host mat-expansion-panel ::ng-deep .mat-expansion-panel-header.alignItemsLeft .mat-content {
  flex: 0 1 auto;
}
:host mat-expansion-panel ::ng-deep .mat-expansion-panel-header.info .mat-expansion-panel-header-title {
  color: var(--l7-info-500-color);
}
/*# sourceMappingURL=UserSettingsView.css.map */
