<div class="function-resolve">
  <mat-chip-listbox>
      <mat-chip [disabled]="is_disabled" id="INSTALMENT" class="indicator-button"
                [ngClass]="{'active': selectedResolveParam === INSTALMENT}"
                (click)="changeResolveParam(INSTALMENT, $event)">Rate
      </mat-chip>
      <mat-chip [disabled]="is_disabled" id="YEARLY_INTEREST" class="indicator-button"
                [ngClass]="{'active': selectedResolveParam === YEARLY_INTEREST}"
                (click)="changeResolveParam(YEARLY_INTEREST, $event)">Zins
      </mat-chip>
      <ng-container *ngIf="!is_hire_purchase_contract">
          <mat-chip [disabled]="is_disabled" id="DOWN_PAYMENT" class="indicator-button"
                  [ngClass]="{'active': selectedResolveParam === DOWN_PAYMENT}"
                  (click)="changeResolveParam(DOWN_PAYMENT, $event)">Anzahlung
          </mat-chip>
          <mat-chip [disabled]="is_disabled || is_va_contract" id="RESIDUAL_VALUE" class="indicator-button"
                  [ngClass]="{'active': selectedResolveParam === RESIDUAL_VALUE}"
                  (click)="changeResolveParam(RESIDUAL_VALUE, $event)">Restwert
          </mat-chip>
      </ng-container>
      <ng-container *ngIf="is_hire_purchase_contract">
          <mat-chip [disabled]="is_disabled || disableFirstRate" id="FIRST_INSTALMENT" class="indicator-button"
                  [ngClass]="{'active': selectedResolveParam === FIRST_INSTALMENT}"
                  (click)="changeResolveParam(FIRST_INSTALMENT, $event)">Erste Rate
          </mat-chip>

          <mat-chip [disabled]="is_disabled || disableLastRate" id="LAST_INSTALMENT" class="indicator-button"
                  [ngClass]="{'active': selectedResolveParam === LAST_INSTALMENT}"
                  (click)="changeResolveParam(LAST_INSTALMENT, $event)">Letzte Rate
          </mat-chip>
      </ng-container>
  </mat-chip-listbox>
</div>
