<mat-card fxFlex
          fxLayout="column">
    <mat-card-content fxFill
                      fxLayout="column"
                      fxLayoutAlign="center center">
        <div>
            <span class="hour">{{ presenter.date | async | date:'HH' }}</span>
            <span class="designator">:</span>
            <span class="minute">{{ presenter.date | async | date:'mm' }}</span>
        </div>
    </mat-card-content>
</mat-card>