import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { UsedCondition } from '../../../../models/enums/UsedCondition.enum';

@Component({
    selector: 'l7-calculator-used-condition-form',
    templateUrl: './calculator-used-condition-form.component.html',
    styleUrls: ['./calculator-used-condition-form.component.scss'],
    standalone: false,
})
export class CalculatorUsedConditionFormComponent {

    public internalForm: UntypedFormGroup;

    @Output() objectConditionHasChanged = new EventEmitter<UsedCondition.NEW | UsedCondition.USED>();

    constructor(
        private readonly _fb: UntypedFormBuilder,
    ) {
        this.internalForm = this._fb.group({
            condition: [UsedCondition.NEW, Validators.required],
        });
    }

    public objectConditionChanged(evt: MatRadioChange): void {
        this.objectConditionHasChanged.emit(evt.value);
    }

}
