<form [formGroup]="calculationForm"
      class="form form-calculation"
      [ngClass]="{'disabled': disableForm}"
      autocomplete="off">
  <ng-container *ngIf="!is_hire_purchase_contract">
    <div class="input-container">
      <mat-form-field appearance="outline" [ngClass]="{'blocked': valueToCalculate === 'DOWN_PAYMENT'}">
        <mat-label>Anzahlung in Euro</mat-label>
        <input type="text"
               formControlName="down_payment"
               matInput
               l7InputRef
               l7MarkAsTouched
               [markOnInit]="true"
               (debounceKeyDown)="setPercentAndOrAbsoluteValues($event)"
               placeholder="Anzahlung in Euro"
               id="input_down-payment"
               currencyMask>
          <mat-error *ngIf="calculationForm.get('down_payment').errors || calculationForm.get('down_payment_percent').errors">
              {{'error.value_between_values' | translate : {param1: '0', param2: downPaymentSettings.maxThreshold * 100 + '% von Objektwert'} }}
          </mat-error>
          <mat-icon matSuffix [fontSet]="'l7'"
                    [fontIcon]="'Alert_Circle'"
                    *ngIf="calculationForm.controls.down_payment_percent.value > downPaymentSettings.minThreshold * 100 && calculationForm.controls.down_payment_percent.value <= downPaymentSettings.maxThreshold * 100 && isDownPaymentWarningMessageActive">
          </mat-icon>
          <mat-hint class="text-truncate" *ngIf="calculationForm.controls.down_payment_percent.value > downPaymentSettings.minThreshold * 100 && calculationForm.controls.down_payment_percent.value <= downPaymentSettings.maxThreshold * 100 && isDownPaymentWarningMessageActive">
              {{ 'calculation.down_payment_warning' | translate }}
          </mat-hint>
      </mat-form-field>
    </div>
    <div class="input-container">
      <mat-form-field appearance="outline" [ngClass]="{'blocked': valueToCalculate === 'DOWN_PAYMENT'}">
        <mat-label>Anzahlung in %</mat-label>
        <input type="text"
               formControlName="down_payment_percent"
               matInput
               l7InputRef
               l7MarkAsTouched
               [markOnInit]="true"
               (debounceKeyDown)="setPercentAndOrAbsoluteValues($event)"
               placeholder="Anzahlung in %"
               id="input_down-payment-percent"
               currencyMask
               [l7Min]="0"
               [l7Max]="100"
               [options]="{suffix: ' %'}">
          <mat-error *ngIf="calculationForm.get('down_payment_percent').errors || calculationForm.get('down_payment').errors">
              {{'error.value_between_values' | translate : {param1: '0', param2: downPaymentSettings.maxThreshold * 100 + '% von Objektwert'} }}
          </mat-error>
          <mat-icon matSuffix [fontSet]="'l7'"
                    [fontIcon]="'Alert_Circle'"
                    *ngIf="calculationForm.controls.down_payment_percent.value > downPaymentSettings.minThreshold * 100 && calculationForm.controls.down_payment_percent.value <= downPaymentSettings.maxThreshold * 100 && isDownPaymentWarningMessageActive">
          </mat-icon>
          <mat-hint class="text-truncate" *ngIf="calculationForm.controls.down_payment_percent.value > downPaymentSettings.minThreshold * 100 && calculationForm.controls.down_payment_percent.value <= downPaymentSettings.maxThreshold * 100 && isDownPaymentWarningMessageActive">
              {{ 'calculation.down_payment_warning' | translate }}
          </mat-hint>
      </mat-form-field>
    </div>
    <div class="input-container">
      <mat-form-field appearance="outline" [ngClass]="{'blocked': valueToCalculate === 'RESIDUAL_VALUE'}">
        <mat-label>Restwert in Euro</mat-label>
        <input type="text"
               formControlName="residual_value"
               matInput
               l7InputRef
               l7MarkAsTouched
               (debounceKeyDown)="setPercentAndOrAbsoluteValues($event)"
               placeholder="Restwert in Euro"
               id="input_residual-value"
               currencyMask>
          <mat-error *ngIf="calculationForm.get('residual_value').errors">
              {{'error.value_between_values' | translate : {param1: minResidualValue * 100, param2: '100% von Objektwert'} }}
          </mat-error>
      </mat-form-field>
    </div>
    <div class="input-container">
      <mat-form-field appearance="outline" [ngClass]="{'blocked': valueToCalculate === 'RESIDUAL_VALUE'}">
        <mat-label>Restwert in %</mat-label>
        <input type="text"
               formControlName="residual_value_percent"
               matInput
               l7InputRef
               l7MarkAsTouched
               (debounceKeyDown)="setPercentAndOrAbsoluteValues($event)"
               placeholder="Restwert in %"
               id="input_residual-value-percent"
               currencyMask
               [l7Min]="0"
               [l7Max]="100"
               [options]="{suffix: ' %'}">
          <mat-error *ngIf="calculationForm.get('residual_value_percent').errors">
              {{'error.value_between_values' | translate : {param1: minResidualValue * 100, param2: '100 %'} }}
          </mat-error>
      </mat-form-field>
    </div>
    <div class="input-container">
      <mat-form-field appearance="outline" [ngClass]="{'blocked': valueToCalculate === 'INSTALMENT'}">
        <mat-label>Rate in Euro</mat-label>
        <input type="text"
               formControlName="instalment"
               matInput
               l7InputRef
               l7MarkAsTouched
               (keyup)="calculationUpdatedSubject.next($event)"
               placeholder="Rate in Euro"
               id="input_instalment"
               currencyMask>
          <mat-error *ngIf="calculationForm.get('instalment').errors">
              {{'error.value_bigger_than' | translate : {param1: '0'} }}
          </mat-error>
      </mat-form-field>
    </div>
    <div class="input-container"
         [formGroup]="provisionForm">
      <mat-form-field class="block" appearance="outline"
                [ngClass]="{'field-hidden': !showAdditionalFields}">
        <mat-label>Leasingfaktor</mat-label>
        <input type="text"
               formControlName="leasing_factor"
               placeholder="Leasingfaktor"
               matInput
               l7InputRef
               l7MarkAsTouched
               (keyup)="calculationUpdatedSubject.next($event)"
               currencyMask
               [options]="{suffix: '', precision: 3}"
               id="input_leasing-factor">
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container *ngIf="is_hire_purchase_contract">
    <div class="input-container hire-purchase">
      <div class="slide-toggle left">
        <mat-slide-toggle (click)="$event.stopPropagation()"
                          (change)="setFirstOrLastRate($event)"
                          labelPosition="before"
                          id="first_instalment"
                          name="handling_first_instalment"
                          color="primary"
                          formControlName="handling_first_instalment">
        </mat-slide-toggle>
      </div>
      <div class="input center">
        <mat-form-field appearance="outline" [ngClass]="{'blocked': valueToCalculate === 'FIRST_INSTALMENT'}" [style]="'min-width: 80%'">
          <mat-label>{{'calculation.first_instalment' | translate}}</mat-label>
          <input type="text"
                 formControlName="first_instalment"
                 matInput
                 l7InputRef
                 l7MarkAsTouched
                 (debounceKeyDown)="setPercentAndOrAbsoluteValues($event)"
                 [placeholder]="'calculation.first_instalment' | translate"
                 id="input_first-instalment-value"
                 currencyMask>
            <mat-error *ngIf="calculationForm.get('first_instalment').errors">
                {{'error.smaller_then' | translate }}
            </mat-error>
        </mat-form-field>
      </div>
      <div class="input right">
        <mat-form-field appearance="outline" [ngClass]="{'blocked': valueToCalculate === 'FIRST_INSTALMENT'}">
          <mat-label>{{'calculation.first_instalment_percent' | translate}}</mat-label>
          <input type="text"
                 formControlName="first_instalment_percent"
                 matInput
                 l7InputRef
                 l7MarkAsTouched
                 (debounceKeyDown)="setPercentAndOrAbsoluteValues($event)"
                 [placeholder]="'calculation.first_instalment_percent' | translate"
                 id="input_first-instalment-percent"
                 currencyMask
                 [options]="{suffix: ' %'}">
        </mat-form-field>
      </div>
    </div>

    <div class="input-container hire-purchase">
      <div class="input left">
        <mat-form-field appearance="outline" class="disabled" [style]="'min-width: 50%'">
            <mat-label>{{'calculation.hire_purchase_terms' | translate}}</mat-label>
            <input type="text"
                 formControlName="hire_purchase_terms"
                 matInput
                 l7InputRef
                 [attr.disabled]="true"
                 [placeholder]="'calculation.hire_purchase_terms' | translate"
                 id="input_hire_purchase_terms">
        </mat-form-field>
      </div>
      <div class="input center">
        <mat-form-field appearance="outline" [ngClass]="{'blocked': valueToCalculate === 'INSTALMENT'}" [style]="'min-width: 80%'">
          <mat-label>{{'calculation.hire_purchase_rate_value' | translate}}</mat-label>
          <input type="text"
                 formControlName="instalment"
                 matInput
                 l7InputRef
                 l7MarkAsTouched
                 (debounceKeyDown)="setPercentAndOrAbsoluteValues($event)"
                 [placeholder]="'calculation.hire_purchase_rate_value' | translate"
                 id="input_instalment-hire-purchase"
                 currencyMask>
            <mat-error *ngIf="calculationForm.get('instalment').errors">
                {{'error.value_bigger_than' | translate : {param1: '0'} }}
            </mat-error>
        </mat-form-field>
      </div>
      <div class="input right">
        <mat-form-field appearance="outline" [ngClass]="{'blocked': valueToCalculate === 'INSTALMENT'}">
          <mat-label>{{'calculation.hire_purchase_rate_percent' | translate}}</mat-label>
          <input type="text"
                 formControlName="instalment_percent"
                 matInput
                 l7InputRef
                 l7MarkAsTouched
                 (debounceKeyDown)="setPercentAndOrAbsoluteValues($event)"
                 [placeholder]="'calculation.hire_purchase_rate_percent' | translate"
                 id="input_instalment-percent"
                 currencyMask
                 [options]="{suffix: ' %'}">
        </mat-form-field>
      </div>
    </div>

    <div class="input-container hire-purchase">
      <div class="slide-toggle left">
        <mat-slide-toggle (click)="$event.stopPropagation()"
                          (change)="setFirstOrLastRate($event)"
                          labelPosition="before"
                          id="last_instalment"
                          name="handling_last_instalment"
                          color="primary"
                          formControlName="handling_last_instalment">
        </mat-slide-toggle>
      </div>
      <div class="input center">
        <mat-form-field appearance="outline" [ngClass]="{'blocked': valueToCalculate === 'LAST_INSTALMENT'}" [style]="'min-width: 80%'">
          <mat-label>{{'calculation.last_instalment' | translate}}</mat-label>
          <input type="text"
                 formControlName="last_instalment"
                 matInput
                 l7InputRef
                 l7MarkAsTouched
                 (debounceKeyDown)="setPercentAndOrAbsoluteValues($event)"
                 [placeholder]="'calculation.last_instalment' | translate"
                 id="input_last-instalment"
                 currencyMask>
            <mat-error *ngIf="calculationForm.get('last_instalment').errors">
                {{'error.smaller_then' | translate }}
            </mat-error>
        </mat-form-field>
      </div>
      <div class="input right">
          <mat-form-field appearance="outline" [ngClass]="{'blocked': valueToCalculate === 'LAST_INSTALMENT'}">
            <mat-label>{{'calculation.last_instalment_percent' | translate}}</mat-label>
            <input formControlName="last_instalment_percent"
                   matInput
                   l7InputRef
                   l7MarkAsTouched
                   (debounceKeyDown)="setPercentAndOrAbsoluteValues($event)"
                   [placeholder]="'calculation.last_instalment_percent' | translate"
                   id="input_last-instalment-percent"
                   currencyMask
                   [options]="{suffix: ' %'}">
          </mat-form-field>
      </div>
    </div>

    <div class="input-container hire-purchase">
      <div class="input total-claim">
          <mat-form-field appearance="outline">
            <mat-label>{{'calculation.total_claim' | translate}}</mat-label>
            <input formControlName="total_instalments"
                 matInput
                 l7InputRef
                 [attr.disabled]="true"
                 [placeholder]="'calculation.total_claim' | translate"
                 id="input_total-claim"
                 currencyMask>
          </mat-form-field>
      </div>
      <div class="input total-claim">
          <mat-form-field appearance="outline">
            <mat-label>{{'calculation.total_claim_taxes' | translate}}</mat-label>
            <input formControlName="total_instalments_vat"
                 matInput
                 l7InputRef
                 [attr.disabled]="true"
                 [placeholder]="'calculation.total_claim_taxes' | translate"
                 id="input_total-claim-taxes"
                 currencyMask>
          </mat-form-field>
      </div>
    </div>
  </ng-container>

  <div class="input-container">
    <mat-form-field appearance="outline" [ngClass]="{'blocked': valueToCalculate === 'YEARLY_INTEREST', 'field-hidden': !showAdditionalFields }">
      <mat-label>Nom. Zins P.A.</mat-label>
      <input formControlName="yearly_interest"
             matInput
             l7InputRef
             l7MarkAsTouched
             (keyup)="calculationUpdatedSubject.next($event)"
             placeholder="Nom. Zins P.A."
             id="input_yearly-interest"
             currencyMask
             [options]="{suffix: ' %', allowNegative: true}">
        <mat-error *ngIf="calculationForm.get('yearly_interest').errors?.min">
            {{'error.value_not_allowed' | translate }}
        </mat-error>
    </mat-form-field>
  </div>
</form>
