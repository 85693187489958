/* apps/l7/src/app/private/components/lessee-list-item/lessee-list-item.component.scss */
:host {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eee;
  min-height: 60px;
  position: relative;
  align-items: center;
  padding: 20px 0;
}
:host .list-item-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}
@media screen and (min-width: 600px) {
  :host .list-item-container {
    flex-direction: row;
  }
}
:host .list-item-container.checked {
  font-weight: bold;
}
:host .list-item-container.disabled {
  background: red !important;
}
:host .list-item-container:hover {
  cursor: pointer;
}
:host .list-item-container .list-item__description {
  display: flex;
  width: 100%;
}
@media screen and (min-width: 600px) {
  :host .list-item-container .list-item__description {
    width: 80%;
  }
}
:host .list-item-container .list-item__submenu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 20px 0;
}
@media screen and (min-width: 600px) {
  :host .list-item-container .list-item__submenu {
    width: 20%;
    padding: 0;
  }
}
:host input[type=checkbox] {
  visibility: hidden;
  height: 0;
  width: 0;
}
:host input[type=checkbox] + label {
  position: relative;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
  pointer-events: none;
}
:host input[type=checkbox] + label > span {
  margin-right: 1em;
  min-width: 1.4em;
  min-height: 1.4em;
  width: 1.4em;
  height: 1.4em;
  background: transparent;
  border: 2px solid #9e9e9e;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
  border-radius: 50%;
  position: relative;
}
:host input[type=checkbox] + label:hover,
:host input[type=checkbox]:focus + label {
  color: #fff;
}
:host input[type=checkbox] + label:hover > span,
:host input[type=checkbox]:focus + label > span {
  background: #fff;
}
:host input[type=checkbox]:checked + label > span {
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
  background: #3891a6;
  border: 2px solid #2b7e95;
}
:host input[type=checkbox]:checked + label > span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg) translateX(-50%);
  transform-origin: 0 25%;
  animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}
@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #fff;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.2em;
    height: 0.5em;
    border-color: #fff;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}
/*# sourceMappingURL=lessee-list-item.component.css.map */
