/* apps/l7/src/app/private/components/calculator/calculation-form/calculation-form.component.scss */
:host mat-form-field .mat-mdc-form-field-error {
  font-size: 9px;
}
:host mat-form-field.blocked ::ng-deep {
}
:host mat-form-field.blocked ::ng-deep .mat-form-field-outline {
  color: #8028bf;
}
:host mat-form-field.blocked ::ng-deep mat-label {
  padding: 3px;
}
:host mat-form-field.blocked ::ng-deep input,
:host mat-form-field.blocked ::ng-deep mat-label {
  color: #8028bf;
}
/*# sourceMappingURL=calculation-form.component.css.map */
