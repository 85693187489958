// #region Imports

import { AnalyticsService } from '@abcfinlab/analytics';
import { ControlsOf, once, TranslationFacade } from '@abcfinlab/core';
import { UiStatesPreservationService } from '@abcfinlab/presentation';
import { BusyBoxService, ToastService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { KnownSettings } from 'libs/explorer/src/Models/KnownSettings';

// #endregion

interface IUserSettingsFormDto {
    userAnalyticsConsent: FormControl<boolean>;
}

/**
 * The presenter of the {@link UserSettingsView} view.
 *
 * @internal
 */
@Injectable()
export class UserSettingsViewPresenter {

    // #region Fields

    private readonly _uiStatesPreservationService: UiStatesPreservationService;
    private readonly _analyticsService: AnalyticsService;
    private readonly _busyBox: BusyBoxService;
    private readonly _toastService: ToastService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _form: FormGroup<ControlsOf<IUserSettingsFormDto>>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the UserSettingsViewPresenter class.
     *
     * @public
     */
    public constructor(uiStatesPreservationService: UiStatesPreservationService, translate: TranslationFacade,
        busyBox: BusyBoxService, toastService: ToastService, analyticsService: AnalyticsService) {
        this._uiStatesPreservationService = uiStatesPreservationService;
        this._analyticsService = analyticsService;
        this._busyBox = busyBox;
        this._toastService = toastService;
        this._translationFacade = translate;
        this._form = new FormGroup<ControlsOf<IUserSettingsFormDto>>({
            userAnalyticsConsent: new FormControl(false),
        });

        this._form.controls.userAnalyticsConsent.valueChanges.subscribe((value) => {
            this._busyBox.show(undefined, '', this._uiStatesPreservationService.set(KnownSettings.USER_ANALYTICS_CONSENT, { value: value }))
                .subscribe((_) => {
                    if (value) {
                        this._analyticsService.load();
                    } else {
                        this._analyticsService.unload();
                    }
                    this._toastService.show(this._translationFacade.translate('global.save_successful'), 'success');
                });
        });
    }

    // #endregion

    // #region Properties

    public get form(): FormGroup<ControlsOf<IUserSettingsFormDto>> {
        return this._form;
    }

    public get languages(): Array<{ code: string; name: string }> {
        return this._translationFacade.getAvailableLanguages().map(x => ({ code: x, name: x }));
    }

    public get currentLanguage(): string {
        return this._translationFacade.getCurrentLanguage();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        once(this._uiStatesPreservationService.get<{ value: boolean }>(KnownSettings.USER_ANALYTICS_CONSENT), (x) => {
            if (x) {
                this._form.controls.userAnalyticsConsent.patchValue(x.value, { emitEvent: false });
            } else if (x === null) {
                this._form.controls.userAnalyticsConsent.patchValue(true, { emitEvent: false });
            }
        });
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @* @internal
     */
    public dispose(): void {
    }

    // #endregion

}
