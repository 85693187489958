<div class="calculator-used-condition-form-container">
  <div class="form-labels">
    Objektzustand
  </div>
  <form [formGroup]="internalForm"
        id="form_condition"
        class="form form-used">
    <mat-radio-group formControlName="condition" (change)="objectConditionChanged($event)">
      <mat-radio-button color="primary"
                        [value]="'NEW'"
                        id="radio_condition-is-new">
        {{ 'calculation.condition_new_label' | translate }}
      </mat-radio-button>
      <mat-radio-button color="primary"
                        [value]="'USED'"
                        id="radio_condition-is-used">
        {{ 'calculation.condition_used_label' | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </form>
</div>
