<div class="list-item-container"
     [ngClass]="classes">
  <div class="list-item__description">
    <input [id]="lessee_crefo_no"
           type="checkbox"
           [checked]="is_checked">
    <label [htmlFor]="lessee_crefo_no">
      <span></span>
      {{lessee_name}} <br>
      {{lessee_address}}
    </label>
  </div>
  <div class="list-item__submenu"
       *ngIf="is_checked">
    <button mat-flat-button
            [color]="'accent'"
            id="button_calculate-quote"
            (click)="onCalculateQuote()"
            [disabled]="list_performing_action | async">Angebot kalkulieren</button>
  </div>
</div>