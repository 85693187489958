<div class="lessee-list">
  <ng-container *ngIf="lessees?.length > 0; else resultIsEmpty">
    <l7-lessee-list-item [lesseeList]="list"
                         *ngFor="let lessee of lessees; let i = index"
                         [lessee]="lessee"
                         (mousedown)="selectLesseeItem(i)"
                         (createQuote)="onCreateQuote($event)">
    </l7-lessee-list-item>
  </ng-container>
</div>

<ng-template #resultIsEmpty>
  <div class="no-result-container">
    <h2>Ihre Suche ergab keine Treffer!</h2>
    <p>Bitte versuchen Sie es später erneut oder ändern Sie die Suchparameter!</p>
    <br>
  </div>
</ng-template>