import { IUserInfoDto } from '@abcfinlab/api/login';
import { once, RoleManager, RoleModule, SESSION_AVAILABLE_TOKEN, TranslationFacade, TranslationModule, USER_INFO_TOKEN } from '@abcfinlab/core';
import { PresenterService } from '@abcfinlab/layout';
import { Inject, NgModule } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { DASHBOARD_ROUTE_PATH } from './ExplorerRoutingModule';

@NgModule({
    imports: [
        TranslationModule,
        RoleModule,
    ],
})
export class ExplorerNavigationModule {

    // #region Ctor

    /**
     * Constructs a new instance of the `ExplorerNavigationModule` class.
     *
     * @public
     */
    public constructor(@Inject(SESSION_AVAILABLE_TOKEN) sessionAvailable: Subject<void>, @Inject(USER_INFO_TOKEN) userInfo: ReplaySubject<IUserInfoDto>, presenterService: PresenterService, roleManager: RoleManager, translationFacade: TranslationFacade) {
        once(translationFacade.translate('Startseite'), (t) => {
            // TODO: check permissions here
            presenterService.setNavigation({
                label: t,
                icon: 'Home',
                order: 10,
                route: DASHBOARD_ROUTE_PATH,
            });
        });
    }

    // #endregion

}
