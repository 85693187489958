/* libs/explorer/src/Views/Widgets/ClockWidgetView.scss */
:host {
  width: 100%;
  height: 100%;
  display: flex;
}
:host mat-card {
  color: var(--l7-grey-800-color);
}
:host mat-card .hour {
  font-size: 3.5em;
  font-weight: bold;
}
:host mat-card .minute {
  font-size: 3.5em;
  font-weight: bold;
}
:host mat-card .designator {
  font-size: 3.5em;
  font-weight: bold;
}
/*# sourceMappingURL=ClockWidgetView.css.map */
