import { ICalculationSettingsDto, SettingsService } from '@abcfinlab/api/global';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * @deprecated - Please use getCalculationSettingsResolver instead.
 */
@Injectable({
    providedIn: 'root',
})
export class CalculationSettingsResolver implements Resolve<ICalculationSettingsDto> {

    constructor(
        private readonly _settingsService: SettingsService,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICalculationSettingsDto> {
        return this._settingsService.getCalculationSettingsDto().pipe(
            catchError(throwError as any),
        );
    }

}
