import { IContractTypeDto } from '@abcfinlab/api/global';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SUPPORTED_INSURANCE_TYPES } from '../../../../../../../../libs/presentation/src/Models/InsuranceTypes.config';
import { hundredProMustBeDisabled } from '../../../../helper/calculation.helper';
import { InsuranceFormDTO } from '../../../../models/CalculationFormDTO';
import { KFZStrategy, MKStrategy, VATAStrategy } from '../../../../models/classes/FeeFormStrategies.classes';
import { ContractTypeId } from '../../../../models/enums/ContractTypeId.enum';
import { InsuranceType, InsuranceViewTypes } from '../../../../models/enums/InsuranceType.enum';
import { IFeeFormStrategy } from '../../../../models/interfaces/IFeeFormStrategy.interface';
import {
    HirePurchaseCalculationDTO,
    LeasingQuoteCalculationDTO,
} from '../../../../models/LeasingQuoteCalculationDTO.interface';
import { ObjectGroupDTO } from '../../../../models/ObjectGroupDTO.interface';

export interface CalculateInsurance {
    values: InsuranceFormDTO;
    event: MouseEvent | MatSelectChange;
}

@UntilDestroy()
@Component({
    selector: 'l7-calculator-fee-form',
    templateUrl: './calculator-fee-form.component.html',
    styleUrls: ['./calculator-fee-form.component.scss'],
    standalone: false,
})
export class CalculatorFeeFormComponent implements OnInit {

    public contractTypes = IContractTypeDto;

    @Output() loaded = new EventEmitter<boolean>();

    @Output() calculate: EventEmitter<CalculateInsurance> = new EventEmitter<CalculateInsurance>();

    @Output() validation = new EventEmitter<boolean>();

    @Input()
    set objectGroup(objectGroup: ObjectGroupDTO) {
        if (objectGroup) {
            this._objectGroup = objectGroup;
            this.onObjectGroupChange(objectGroup);
        }
    }

    // @TODO next refactoring consider remove this dependency
    @Input()
    set calculation(calculation: LeasingQuoteCalculationDTO | HirePurchaseCalculationDTO) {
        if (calculation && !!calculation.contract_type) {
            this._calculation = calculation;
            this._patchHandlingFee();
            this.loaded.emit(true);
        }
    }

    get calculation() {
        return this._calculation;
    }

    get showProMilleField() {
        return this.internalForm.getRawValue().insurance_type !== InsuranceType.HUNDRED_PRO
            && this.internalForm.getRawValue().insurance_type !== InsuranceType.NO
            && this.view !== InsuranceViewTypes.QUOTE_DETAIL && this.showAdditionalFields;
    }

    @Input() set contractType(value: ContractTypeId) {
        const res = hundredProMustBeDisabled(this._calculation?.total_leasing_value, parseInt(this._objectGroup?.code));
        if (value === ContractTypeId.KFZ) {
            this.disable100Pro(res);
        }
        this.onChangingContractType(value, this._contractType);
        this._contractType = value;
    }

    @Input() set totalLeasingValue(value: number) {
        this.onChangingTotalLeasingValue(value, this._totalLeasingValue);
        this._totalLeasingValue = value;
    }

    @Input() ngxsStateName: string;

    @Input() view: InsuranceViewTypes;

    @Input() showAdditionalFields: boolean;

    private readonly STANDARD_HANDLING_FEE_VALUE = 200;
    public _objectGroup: ObjectGroupDTO;
    public _calculation: LeasingQuoteCalculationDTO | HirePurchaseCalculationDTO = null;
    private _contractType: ContractTypeId = null;
    private _totalLeasingValue: number = null;
    public internalForm: UntypedFormGroup;
    public strategy: IFeeFormStrategy;
    public insuranceTypes: any = SUPPORTED_INSURANCE_TYPES;
    public showWarningForHandlingFee: boolean;

    constructor(
        private readonly _fb: UntypedFormBuilder,
    ) {
        /**
         *  Setup the insurance specific form and values.
         *  It consists of:
         *    insurance                 = Calculation has "Versicherung"
         *    insurance_fee             = Amount of "Versicherung"
         *    insurance_type            = Type of "Versicherung" NO | PRO100 | STANDARD | MIETKAUF
         *    handling_fee              = Calculation has "Bearbeitungsgebühr"
         *    handling_fee_value        = Amount of "Bearbeitungsgebühr"
         */
        this.internalForm = this._fb.group({
            insurance: [{ value: false, disabled: true }],
            insurance_fee: [{ value: null, disabled: true }],
            insurance_type: [{ value: InsuranceType.NO, disabled: false }],
            total_insurance_instalments: [{ value: null, disabled: true }],
            total_insurance_instalments_vat: [{ value: null, disabled: true }],
            handling_fee: [{ value: true, disabled: false }],
            handling_fee_value: [{ value: this.STANDARD_HANDLING_FEE_VALUE, disabled: false }],
            insurance_pro_mille: [{ value: null, disabled: true }],
        });
    }

    ngOnInit() {
        this.onCalculate(null);

        this.internalForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe((value) => {
                const isValid = value === 'VALID';
                this.validation.emit(isValid);
            });

        this.internalForm.get('insurance').valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((active) => {
                try {
                    this.strategy.toggleValidation(active);
                    this.strategy.handleInsuranceChange(active);
                } catch (error) {
                    console.error(error);
                }
            });

        this.internalForm.get('handling_fee').valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((fee) => {
                try {
                    this.toggleHandlingFee(fee);
                } catch (error) { console.error(error); }
            });
    }

    /**
     * Initially patch the handling fee value input (e.g. if no handling fee is present (false))
     * @private
     */
    private _patchHandlingFee(): void {
        if (!this._calculation.handling_fee) {
            this.internalForm.get('handling_fee_value').disable({ emitEvent: false });
        }
    }

    /**
     *  Disable the insurance selection in dropdown by function expression bound to the disabled attribute on the select element.
     *  Standard type insurances are not allowed / selectable if the object group insurance values is 0.
     */
    insuranceTypeDisabled(insuranceType: InsuranceType): boolean {
        return (this._objectGroup?.insurance_value === 0 && insuranceType === InsuranceType.STANDARD)
            || (hundredProMustBeDisabled(this._calculation?.total_leasing_value, parseInt(this._objectGroup?.code))
                && insuranceType === InsuranceType.HUNDRED_PRO);
    }

    handleInsuranceChange(evt: MatSlideToggleChange) {
        try {
            this.strategy.handleInsuranceChange(evt.checked);
            this.onCalculate(evt);
        } catch (error) { console.error(error); }
    }

    /**
     * Sets the insurance handling behaviour based on the different types of contracts.
     * Should only be called if the contract_type changes.
     * @param contractType
     * @private
     */
    private _setStrategy(contractType: ContractTypeId): void {
        try {
            if (contractType === ContractTypeId.KFZ) {
                this.strategy = new KFZStrategy(this.internalForm, this._calculation, this._objectGroup, this.view);
                return;
            }
            if (contractType === ContractTypeId.VA || contractType === ContractTypeId.TA) {
                this.strategy = new VATAStrategy(this.internalForm, this._calculation, this._objectGroup, this.view);
                return;
            }
            if (contractType === ContractTypeId.MIETKAUF || contractType === ContractTypeId.MKN) {
                this.strategy = new MKStrategy(this.internalForm, this._calculation, this._objectGroup, this.view);
            }
        } catch (e) {
            console.warn('Could not create FeeFormStrategy ...');
        }
    }

    toggleHandlingFee(hasHandlingFee: boolean) {
        if (hasHandlingFee) {
            this.internalForm.get('handling_fee_value').enable();
            this.internalForm.get('handling_fee_value').patchValue(this.STANDARD_HANDLING_FEE_VALUE);
        } else {
            this.internalForm.get('handling_fee_value').disable();
            this.internalForm.get('handling_fee_value').patchValue(null);
        }
    }

    handleInsuranceSelection(evt: MatSelectChange) {
        if (evt.value) {
            this.strategy.handleInsuranceTypeChange(evt.value);
            this.onCalculate(evt);
        }
    }

    onCalculate(evt) {
        if (this.internalForm.valid) {
            this.calculate.emit({ values: this.internalForm.getRawValue(), event: evt });
        } else {
            console.warn('FeeFormComponent not valid!');
        }
    }

    onObjectGroupChange(objectGroup) {
        if (this.view === InsuranceViewTypes.QUOTE_DETAIL) {
            return;
        }

        if (this.strategy) {
            if (this.view !== InsuranceViewTypes.UPDATE) {
                this.strategy = null;
                return;
            }
            this.strategy.handleObjectGroupChange(objectGroup);
        }
    }

    onChangingContractType(value: ContractTypeId, previousValue: ContractTypeId) {
        if (!value) {
            return;
        }

        if (!!this._objectGroup && !!this.strategy) {
            this._setStrategy(value);
            this.strategy.handleInsuranceFeeEnabled();
        }

        if (value !== previousValue || !this.strategy) {
            this._setStrategy(value);
            this.strategy.init();
        }
    }

    onChangingTotalLeasingValue(value: number, previousValue: number) {
        const res = hundredProMustBeDisabled(
            this._calculation?.total_leasing_value,
            parseInt(this._objectGroup?.code));
        if (this._contractType === ContractTypeId.KFZ) {
            this.disable100Pro(res);
        }
        if (this.view === InsuranceViewTypes.QUOTE_DETAIL) {
            return;
        }

        if (value !== previousValue) {
            this.strategy?.handleValueChange({
                ...this._calculation,
                total_leasing_value: value,
            } as LeasingQuoteCalculationDTO);
            this.onCalculate(null);
        }
    }

    private disable100Pro(value: boolean) {
        if (value) {
            this.internalForm.get('insurance').disable();
        } else {
            this.internalForm.get('insurance').enable();
        }
    }

}
