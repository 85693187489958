// #region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UserSettingsViewPresenter } from 'libs/explorer/src/Views/user-settings-view/UserSettingsViewPresenter';

// #endregion

/**
 * The UserSettingsView view.
 *
 * @public
 */
@Component({
    selector: 'l7-user-settings-view',
    templateUrl: './UserSettingsView.html',
    styleUrls: ['./UserSettingsView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        UserSettingsViewPresenter,
    ],
    standalone: false,
})
export class UserSettingsView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: UserSettingsViewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the userSettings class.
     *
     * @public
     */
    public constructor(presenter: UserSettingsViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Properties
    public isOpened: boolean;
    /**
     * Returns the presenter of the userSettings
     *
     * @public
     * @readonly
     */
    public get presenter(): UserSettingsViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
