/* libs/explorer/src/Views/Widgets/CalendarWidgetView.scss */
:host {
  width: 100%;
  height: 100%;
  display: flex;
}
:host mat-card {
  color: var(--l7-grey-800-color);
}
:host mat-card .day-name {
  font-size: 1.5em;
  margin: 0;
}
:host mat-card .day {
  font-size: 2.5em;
  font-weight: bold;
  margin: 0;
}
:host mat-card .month-name {
  font-size: 1.5em;
  margin: 0;
}
:host mat-card .designator {
  height: 2px;
  width: 48px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 2px;
  background-color: currentColor;
}
:host mat-card .year {
  font-size: 2.5em;
  font-weight: bold;
}
/*# sourceMappingURL=CalendarWidgetView.css.map */
