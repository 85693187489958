import { AbstractControl } from '@angular/forms';

export function validate100pro(control: AbstractControl) {
    const minimumValue = 0;
    const maximumValue = 35;
    const insuranceValue = control.value;
    const isValid = insuranceValue >= minimumValue && insuranceValue <= maximumValue;

    // error case first
    if (!isValid) {
        return {
            valid100pro: {
                valid: false,
            },
        };
    }
    return null;
}
