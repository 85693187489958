/* libs/explorer/src/Views/DashboardView.scss */
:host {
  display: block;
  width: 100%;
  height: 100%;
}
:host .alert {
  background: #c3dee4;
  color: #19485a;
  padding: 16px 28px;
  border-radius: 8px;
  transition: opacity 0.25ms ease-in-out;
}
:host .alert .alert-text {
  margin: 0 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
:host .alert .alert-text.sm {
  margin: 0 0 0 16px;
}
:host .alert .alert-action.sm {
  margin: 16px 0 0 0;
}
:host .welcome-container {
  margin-bottom: 24px;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 32px;
}
:host .welcome-container h2 {
  font-size: 1.6em;
  font-size: 2em;
}
:host .widget-container {
  flex: 1;
  position: relative;
  margin: -16px;
}
:host .widget-container mat-grid-tile.disabled {
  -webkit-user-select: none;
  user-select: none;
}
:host .widget-container .grid-list-item {
  display: flex;
  position: absolute;
  top: 16px;
  left: 16px;
  right: 16px;
  bottom: 16px;
}
:host .faq-container {
  margin-bottom: 24px;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 32px;
}
:host .faq-container h2 {
  font-size: 1.6em;
  font-size: 2em;
}
/*# sourceMappingURL=DashboardView.css.map */
