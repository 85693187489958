// #region Imports

import { TranslationModule } from '@abcfinlab/core';
import { LayoutModule } from '@abcfinlab/layout';
import { TileRegistry } from '@abcfinlab/presentation';
import { BannerModule, GreetingModule, MotionModule, Motions, TextBlockModule } from '@abcfinlab/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { DashboardView } from '../Views/DashboardView';
import { UserSettingsView } from '../Views/user-settings-view/UserSettingsView';
import { CalendarWidgetView } from '../Views/Widgets/CalendarWidgetView';
import { ClockWidgetView } from '../Views/Widgets/ClockWidgetView';

// #endregion

/**
 * The `ExplorerModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        TranslationModule,
        MatIconModule,
        MatRippleModule,
        MatButtonModule,
        MatExpansionModule,
        GreetingModule,
        MatCardModule,
        LayoutModule,
        MatDividerModule,
        TextBlockModule,
        BannerModule,
        MatGridListModule,
        MotionModule.forRoot({
            motions: [
                Motions.fadeSlideIn,
            ],
        }),
        FormsModule,
        MatRadioModule,
    ],
    exports: [
        DashboardView,
        UserSettingsView,
    ],
    declarations: [
        DashboardView,
        UserSettingsView,
        ClockWidgetView,
        CalendarWidgetView,
    ],
})
export class ExplorerModule {

    // #region Ctor

    /**
     * Constructs a new instance of the `ExplorerModule` class.
     *
     * @public
     */
    public constructor(tileRegistry: TileRegistry) {
        tileRegistry.register({
            key: 'CALENDAR',
            order: 30,
            columnSpan: { min: 1, max: 1 },
            rowSpan: { min: 1, max: 1 },
            views: {
                front: CalendarWidgetView,
            },
        });

        tileRegistry.register({
            key: 'CLOCK',
            order: 40,
            columnSpan: { min: 1, max: 1 },
            rowSpan: { min: 1, max: 1 },
            views: {
                front: ClockWidgetView,
            },
        });
    }

    // #endregion

}
