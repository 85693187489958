/* apps/l7/src/app/private/components/lessee-list/lessee-list.component.scss */
:host .lessee-list {
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 600px) {
  :host .lessee-list {
    padding: 20px;
  }
}
:host .no-result-container {
  text-align: center;
  padding: 10px;
  margin-top: 50px;
}
/*# sourceMappingURL=lessee-list.component.css.map */
