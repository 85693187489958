import { IDownPaymentSettingsDto } from '@abcfinlab/api/global';
import { FeatureManager, FormValidator } from '@abcfinlab/core';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { contractTypeHasResidualValue, getPercent, getPercentageValue } from '../../../../helper/calculation.helper';
import { ContractTypeId } from '../../../../models/enums/ContractTypeId.enum';
import { ValueToCalculate } from '../../../../models/enums/ValueToCalculate.enum';
import { CalculationState } from '../../../../state/Calculation.state';

@UntilDestroy()
@Component({
    selector: 'l7-calculation-form',
    templateUrl: './calculation-form.component.html',
    styleUrls: ['./calculation-form.component.scss'],
    standalone: false,
})
export class CalculationFormComponent implements OnInit {

    public disableForm = true;

    public valueToCalculate: ValueToCalculate;

    public isDownPaymentWarningMessageActive: boolean;
    public calculationUpdatedSubject: Subject< KeyboardEvent > = new Subject<KeyboardEvent>();
    private _isBasicFormValid: boolean;

    @Input() contractType: ContractTypeId;
    @Input() totalValue: number;
    @Input() calculationForm: UntypedFormGroup;
    @Input() set isFormValid(value: boolean) {
        this.enableDisableFormControlsOnValidityChange(value);
        this._isBasicFormValid = value;
        this.disableForm = !value;
    }

    @Input() showAdditionalFields: boolean;
    // TODO remove/refactor dependency
    @Input() provisionForm: UntypedFormGroup;

    @Input() set calculationErrors(values: Array<{ key: string }>) {
        this.calculationForm.get('yearly_interest').setErrors(null);
        if (values?.length) {
            values.map((value) => {
                switch (value.key) {
                    case 'calculation_yearly_interest_zero':
                        this.calculationForm.get('yearly_interest').setErrors({ min: true });
                        break;
                }
            });
        }
    }

    @Input() minResidualValue: number;

    @Input() downPaymentSettings: IDownPaymentSettingsDto;

    @Output() calculate: EventEmitter<KeyboardEvent | MouseEvent | UIEvent | MatSlideToggleChange> = new EventEmitter< MouseEvent | UIEvent | MatSlideToggleChange>();

    @Output() setFunctionParam: EventEmitter<any> = new EventEmitter<any>();

    public valueToCalculate$: Observable<ValueToCalculate>;
    public contractType$: Observable<ContractTypeId>;
    private readonly _formValidator: FormValidator;

    constructor(private readonly _featureManager: FeatureManager, private readonly _store: Store, formValidator: FormValidator) {
        this.valueToCalculate$ = this._store.select(CalculationState.GetValueToCalculate);
        this.contractType$ = this._store.select(CalculationState.GetContractType);
        this._formValidator = formValidator;
        this.isDownPaymentWarningMessageActive = this._featureManager.active('downPaymentWarningMessage');
        this.calculationUpdatedSubject.pipe(
            distinctUntilChanged(),
            debounceTime(1000),
            untilDestroyed(this),
            mergeMap(search => of(search).pipe(
                delay(500),
            )),
        ).subscribe((evt) => {
            const ignoreKeysList = ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', ',', '.'];
            if (ignoreKeysList.indexOf(evt.key) < 0) {
                this.calculate.emit(evt);
            }
        });
    }

    get is_hire_purchase_contract(): boolean {
        return this.contractType === ContractTypeId.MIETKAUF;
    }

    ngOnInit() {
        this.valueToCalculate$
            .pipe(untilDestroyed(this))
            .subscribe((_value) => {
                this.valueToCalculate = _value;
                if (this._isBasicFormValid) {
                    this.handleFunctionParamChange(_value);
                }
            });

        this.contractType$
            .pipe(untilDestroyed(this))
            .subscribe((_value) => {
                if (_value !== ContractTypeId.MIETKAUF) {
                    this.enableDisableFirstAndOrLastRate(['first_instalment', 'last_instalment'], false);
                    this.calculationForm?.get('handling_first_instalment').patchValue(false, { onlySelf: true, emitEvent: false });
                    this.calculationForm?.get('handling_last_instalment').patchValue(false, { onlySelf: true, emitEvent: false });
                }
            });
    }

    // On first/last instalment toggle
    setFirstOrLastRate(evt: MatSlideToggleChange) {
        this.enableDisableFirstAndOrLastRate([evt.source.id], evt.checked);

        this.handlingTerms(!evt.checked);

        if (!evt.checked) {
            const param: string = evt.source.id === 'first_instalment' ? ValueToCalculate.FIRST_INSTALMENT : ValueToCalculate.LAST_INSTALMENT;
            this.activateValueToCalculate(param, 0);
            this.calculate.emit(evt);
        } else if (evt.checked && !this.calculationForm.getRawValue()[evt.source.id]) {
            this.calculate.emit(evt);
        }
    }

    setPercentAndOrAbsoluteValues(evt) {
        evt?.stopPropagation();
        const formControlName: string = evt?.target?.attributes?.formControlName?.value;

        // Set absolute values and percentage
        switch (formControlName) {
            case 'residual_value_percent':
                this.calculationForm.patchValue({
                    residual_value: getPercentageValue(this.calculationForm.getRawValue().residual_value_percent, this.totalValue),
                }, { emitEvent: false });
                break;
            case 'residual_value':
                this.calculationForm.patchValue({
                    residual_value_percent: getPercent(this.totalValue, this.calculationForm.getRawValue().residual_value, 2, true),
                }, { onlySelf: false, emitEvent: false });
                break;
            case 'down_payment_percent':
                this.calculationForm.patchValue({
                    down_payment: getPercentageValue(this.calculationForm.getRawValue().down_payment_percent, this.totalValue),
                }, { onlySelf: false, emitEvent: false });
                break;
            case 'down_payment':
                this.calculationForm.patchValue({
                    down_payment_percent: getPercent(this.totalValue, this.calculationForm.getRawValue().down_payment, 2, true),
                }, { onlySelf: false, emitEvent: false });
                break;
            case 'first_instalment':
                this.calculationForm.patchValue({
                    first_instalment_percent: getPercent(this.totalValue, this.calculationForm.getRawValue().first_instalment, 2, true),
                }, { onlySelf: false, emitEvent: false });
                this.activateValueToCalculate(ValueToCalculate.FIRST_INSTALMENT, this.calculationForm.getRawValue().first_instalment);
                break;
            case 'first_instalment_percent':
                this.calculationForm.patchValue({
                    first_instalment: getPercentageValue(this.calculationForm.getRawValue().first_instalment_percent, this.totalValue),
                }, { onlySelf: false, emitEvent: false });
                this.activateValueToCalculate(ValueToCalculate.FIRST_INSTALMENT, this.calculationForm.getRawValue().first_instalment);
                break;
            case 'last_instalment':
                this.calculationForm.patchValue({
                    last_instalment_percent: getPercent(this.totalValue, this.calculationForm.getRawValue().last_instalment, 2, true),
                }, { onlySelf: false, emitEvent: false });
                this.activateValueToCalculate(ValueToCalculate.LAST_INSTALMENT, this.calculationForm.getRawValue().last_instalment);
                break;
            case 'last_instalment_percent':
                this.calculationForm.patchValue({
                    last_instalment: getPercentageValue(this.calculationForm.getRawValue().last_instalment_percent, this.totalValue),
                }, { onlySelf: false, emitEvent: false });
                this.activateValueToCalculate(ValueToCalculate.LAST_INSTALMENT, this.calculationForm.getRawValue().last_instalment);
                break;
            case 'instalment':
                this.calculationForm.patchValue({
                    instalment_percent: getPercent(this.totalValue, this.calculationForm.getRawValue().instalment, 2, true),
                }, { onlySelf: false, emitEvent: false });
                break;
            case 'instalment_percent':
                this.calculationForm.patchValue({
                    instalment: getPercentageValue(this.calculationForm.getRawValue().instalment_percent, this.totalValue),
                }, { onlySelf: false, emitEvent: false });
                break;
        }
        this.calculationUpdatedSubject.next(evt);
    }

    private activateValueToCalculate(param: string, controlValue: number) {
        if (controlValue) {
            this.setFunctionParam.emit({ param, value: true });
            return;
        }

        this.setFunctionParam.emit({ param, value: false });
    }

    private enableDisableFormControlsOnValidityChange(value: boolean) {
        if (value) {
            this.calculationForm?.get('handling_first_instalment').enable({ onlySelf: true, emitEvent: false });
            this.calculationForm?.get('handling_last_instalment').enable({ onlySelf: true, emitEvent: false });
            return;
        }
        this.calculationForm?.get('handling_first_instalment').disable({ onlySelf: true, emitEvent: false });
        this.calculationForm?.get('handling_last_instalment').disable({ onlySelf: true, emitEvent: false });
    }

    private enableDisableFirstAndOrLastRate(fields: Array<string>, value: boolean) {
        if (value) {
            fields.forEach((field) => {
                this.calculationForm.get(field).enable({ onlySelf: true, emitEvent: false });
                this.calculationForm.get(`${field}_percent`).enable({ onlySelf: true, emitEvent: false });
            });
        } else {
            fields.forEach((field) => {
                this.calculationForm.get(field).disable({ onlySelf: true, emitEvent: false });
                this.calculationForm.get(field).patchValue(null, { onlySelf: true, emitEvent: false });
                this.calculationForm.get(`${field}_percent`).disable({ onlySelf: true, emitEvent: false });
                this.calculationForm.get(`${field}_percent`).patchValue(null, { onlySelf: true, emitEvent: false });
            });
        }
    }

    private handlingTerms(value: boolean) {
        let termsValue = this.calculationForm.getRawValue().hire_purchase_terms;
        if (value) {
            termsValue++;
        } else {
            termsValue--;
        }
        this.calculationForm.get('hire_purchase_terms').patchValue(
            termsValue,
            { emitEvent: false, onlySelf: true },
        );
    }

    /**
   * React on changing the function resolve parameter
   */
    public handleFunctionParamChange(param: ValueToCalculate): void {
        if (typeof param === 'undefined') {
            return;
        }
        const contractType = this.contractType;

        try {
            const calculationForm = this.calculationForm.controls;
            // enable all controls again before disabling the selected ones
            Object.entries(calculationForm).forEach(([_, control]) => {
                control.enable({ emitEvent: false });
            });

            if (param === ValueToCalculate.RESIDUAL_VALUE || !contractTypeHasResidualValue(contractType)) {
                // Disable fields *Restwert*
                calculationForm.residual_value.disable({ emitEvent: false, onlySelf: true });
                calculationForm.residual_value_percent.disable({ emitEvent: false, onlySelf: true });
            }

            if (param === ValueToCalculate.YEARLY_INTEREST) {
                // Disable fields *Zins*
                calculationForm.yearly_interest.disable({ emitEvent: false, onlySelf: true });
            }

            if (this.contractType === ContractTypeId.MIETKAUF) {
                calculationForm.total_instalments.disable({ emitEvent: false });
                calculationForm.total_instalments_vat.disable({ emitEvent: false });
            }

            const isInstalmentOrYearlyInterest = (param === ValueToCalculate.YEARLY_INTEREST || param === ValueToCalculate.INSTALMENT);
            if (isInstalmentOrYearlyInterest && this.contractType === ContractTypeId.MIETKAUF) {
                if (!this.calculationForm.getRawValue().handling_first_instalment) {
                    this.enableDisableFirstAndOrLastRate(['first_instalment'], false);
                }
                if (!this.calculationForm.getRawValue().handling_last_instalment) {
                    this.enableDisableFirstAndOrLastRate(['last_instalment'], false);
                }
            }

            if (param === ValueToCalculate.DOWN_PAYMENT) {
                // Disable fields *Anzahlung*
                calculationForm.down_payment.disable({ emitEvent: false, onlySelf: true });
                calculationForm.down_payment_percent.disable({ emitEvent: false, onlySelf: true });
            }

            if (param === ValueToCalculate.INSTALMENT) {
                // Disable field on Rate
                calculationForm.instalment.disable({ emitEvent: false });
                if (this.contractType === ContractTypeId.MIETKAUF) {
                    calculationForm.instalment_percent.disable({ emitEvent: false });
                }
            }

            if (param === ValueToCalculate.FIRST_INSTALMENT) {
                calculationForm.first_instalment.disable({ emitEvent: false });
                calculationForm.first_instalment_percent.disable({ emitEvent: false });
                if (!this.calculationForm.getRawValue().handling_last_instalment) {
                    this.enableDisableFirstAndOrLastRate(['last_instalment'], false);
                }
            }

            if (param === ValueToCalculate.LAST_INSTALMENT) {
                calculationForm.last_instalment.disable({ emitEvent: false });
                calculationForm.last_instalment_percent.disable({ emitEvent: false });
                if (!this.calculationForm.getRawValue().handling_first_instalment) {
                    this.enableDisableFirstAndOrLastRate(['first_instalment'], false);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

}
