import { Component } from '@angular/core';

@Component({
    selector: 'l7-container-quotes',
    templateUrl: './container-quotes.component.html',
    styleUrls: ['./container-quotes.component.scss'],
    standalone: false,
})
export class ContainerQuotesComponent {

    constructor() {
    }

}
