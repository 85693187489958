/* apps/l7/src/app/private/components/calculator/calculator-used-condition-form/calculator-used-condition-form.component.scss */
:host .calculator-used-condition-form-container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
@media screen and (min-width: 1024px) {
  :host .calculator-used-condition-form-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
:host .calculator-used-condition-form-container .form-labels {
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 17px;
}
@media screen and (min-width: 1024px) {
  :host .calculator-used-condition-form-container .form-labels {
    padding-bottom: unset;
  }
}
:host .calculator-used-condition-form-container .form.form-used {
  display: grid;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 1280px) {
  :host .calculator-used-condition-form-container .form.form-used {
    width: 60%;
  }
}
/*# sourceMappingURL=calculator-used-condition-form.component.css.map */
