<l7-page fxFlex>
    <l7-page-header [title]="'dashboard.userSettings.title' | translate"
                    motion
                    [motionPreset]="'fadeSlideIn'">
    </l7-page-header>
    <l7-page-content fxFlex
                     fxLayout="column">
        <l7-page-section fxFlex
                         fxLayout="column wrap">
            <form [formGroup]="presenter.form"
                  *ngIf="presenter.form">
                <div fxLayout="column"
                     fxLayoutGap="16px">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>{{ 'dashboard.userSettings.language.title' | translate }}</mat-card-title>
                            <mat-card-subtitle>{{ 'dashboard.userSettings.language.subTitle' | translate }}</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <mat-radio-group>
                                <mat-radio-button *ngFor="let language of presenter.languages"
                                                  [value]="language.code"
                                                  [checked]="presenter.currentLanguage === language.code">
                                    {{ language.name }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </mat-card-content>
                    </mat-card>
                    <mat-card>
                        <mat-card-header class="wrap"
                                         fxLayout="column">
                            <mat-card-title fxFlex="100"
                                            fxLayout="row">
                                <div>{{ 'Microsoft Clarity' | translate }}</div>
                            </mat-card-title>
                            <mat-card-subtitle fxFlex="100"
                                               fxLayout="row">
                                <div>{{ 'dashboard.userSettings.analytics.subTitle' | translate }}</div>
                            </mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content fxLayout="row wrap"
                                          fxLayoutGap="16px">
                            <div fxLayout="row"
                                 fxFlex="100"
                                 fxLayoutGap="16px">
                                <mat-radio-group formControlName="userAnalyticsConsent"
                                                 fxLayout="column">
                                    <mat-radio-button color="primary"
                                                      [value]="true"
                                                      test-id="radioConsentTrue">
                                        {{ 'global.yes' | translate }}
                                    </mat-radio-button>
                                    <mat-radio-button color="primary"
                                                      [value]="false"
                                                      test-id="radioConsentFalse">
                                        {{ 'global.no' | translate }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row"
                                 fxFlex="70">
                                <mat-expansion-panel class="mat-elevation-z0"
                                                     [hideToggle]="true"
                                                     (closed)="isOpened=false"
                                                     (opened)="isOpened=true">
                                    <mat-expansion-panel-header class="alignItemsLeft info">
                                        <mat-panel-title>
                                            {{ 'dashboard.userSettings.analytics.expansionPanelTitle' | translate }}
                                        </mat-panel-title>
                                        <mat-icon fontSet="l7"
                                                  fontIcon="Chevron_Up"
                                                  color="primary"
                                                  *ngIf="isOpened; else isClosed">
                                        </mat-icon>
                                        <ng-template #isClosed>
                                            <mat-icon fontSet="l7"
                                                      fontIcon="Chevron_Down"
                                                      color="primary">
                                            </mat-icon>
                                        </ng-template>
                                    </mat-expansion-panel-header>
                                    <p>{{ 'dashboard.userSettings.analytics.expansionPanelText' | translate }}</p>
                                </mat-expansion-panel>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </form>
        </l7-page-section>
    </l7-page-content>
</l7-page>