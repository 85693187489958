import { IContactDto } from '@abcfinlab/api/contact';
import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { ContactDTO } from '../../../models/ContactDTO.interface';
import { LeasingQuoteDTO } from '../../../models/LeasingQuoteDTO.interface';
import { QuoteRepositoryService } from '../../repository/quote-repository.service';
import { LesseeListItemComponent } from '../lessee-list-item/lessee-list-item.component';

@UntilDestroy()
@Component({
    selector: 'l7-lessee-list',
    templateUrl: './lessee-list.component.html',
    styleUrls: ['./lessee-list.component.scss'],
    standalone: false,
})
export class LesseeListComponent {

    @Input() lessees: Array<IContactDto>;
    @ViewChildren(LesseeListItemComponent) lesseeListItems: QueryList<LesseeListItemComponent>;
    @Output() nextScreen: EventEmitter<LeasingQuoteDTO> = new EventEmitter<LeasingQuoteDTO>();
    public lesseeIsSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly _quoteRepositoryService: QuoteRepositoryService,
    ) {
    }

    get list(): LesseeListComponent {
        return this;
    }

    /**
 *  Select a lessee from the list
 */
    public selectLesseeItem(itemIndex: number) {
        this.lesseeListItems.forEach((item, index, array) => {
            item.checked = index === itemIndex;
        });
        this.lesseeIsSelected.next(true);
    }

    /**
 * Event handler function to react on the button click on the list element component if the user wants to start a new calculation to
 * a given lessee from cam search result.
 * @param     lessee      The search response object
 */
    public onCreateQuote(lessee: ContactDTO) {
        this.isLoading$.next(true);
        this._quoteRepositoryService.createQuoteV2(lessee)
            .pipe(untilDestroyed(this))
            .subscribe((result: LeasingQuoteDTO) => {
                this.isLoading$.next(false);
                this.nextScreen.emit(result);
            }, (err) => {
                this.isLoading$.next(false);
            });
    }

}
