<form [formGroup]="internalForm"
      ngxsForm="{{ngxsStateName}}.insuranceForm"
      class="form form-insurance">
  <!-- KEIN MIETKAUF -->
  <ng-container *ngIf="_calculation?.contract_type !== contractTypes.Mietkauf && _calculation?.contract_type !== contractTypes.Mkn">
    <input type="hidden"
           formControlName="insurance_type"
           l7InputRef
           id="input_insurance-type">
      <ng-container *ngIf="view === 'QUOTE_DETAIL'">
          <div class="input-container" fxLayout="column">
              <h3 class="checkbox-label" *ngIf="strategy">
                  {{ 'calculation.calculatorFeeForm.addStrategy' | translate: {strategy: strategy?.name} }}
              </h3>
              <h3 class="checkbox-label" *ngIf="!strategy">
                  {{ 'calculation.calculatorFeeForm.addStrategy' | translate: {strategy: 'Versicherung'} }}
              </h3><br>
              <div fxLayout="row">
                  <div fxFlex="10" class="slide-toggle">
                      <mat-slide-toggle id="toggle_insurance"
                                        labelPosition="top"
                                        color="primary"
                                        formControlName="insurance"
                                        (change)="handleInsuranceChange($event)">
                      </mat-slide-toggle>
                  </div>
                  <div fxFlex="90">
                      <mat-form-field appearance="outline" class="input">
                          <mat-label>{{strategy?.name === '100pro' ? '100pro' : 'Monatliche Prämie'}}</mat-label>
                          <input id="input_insurance-fee"
                                 matInput
                                 formControlName="insurance_fee"
                                 l7InputRef
                                 (debounceKeyDown)="onCalculate($event)"
                                 [placeholder]="strategy?.name === '100pro' ? '100pro' : 'Monatliche Prämie'"
                                 currencyMask
                                 l7MarkAsTouched
                                 [readOnly]="strategy?.name === '100pro'">
                          <mat-error *ngIf="internalForm.get('insurance_fee').errors">
                              {{ strategy?.getErrorMessage() }}
                          </mat-error>
                      </mat-form-field>
                  </div>
              </div>
          </div>
          <div class="input-container"
               *ngIf="showProMilleField">
              <div class="slide-toggle">

              </div>
              <div class="input">
                  <mat-form-field appearance="outline">
                      <mat-label>{{'calculation.pro_mille_placeholder' | translate}}</mat-label>
                      <input id="input_insurance-pro-mille"
                             matInput
                             formControlName="insurance_pro_mille"
                             [placeholder]="'calculation.pro_mille_placeholder' | translate"
                             l7InputRef
                             currencyMask
                             [l7Min]="0"
                             [l7Max]="100"
                             [options]="{suffix: ' ‰', precision: 4}">
                  </mat-form-field>
              </div>
          </div>
      </ng-container>
      <ng-container *ngIf="view !== 'QUOTE_DETAIL'">
          <div class="input-container">
              <div class="slide-toggle">
                  <mat-slide-toggle id="toggle_insurance"
                                    labelPosition="before"
                                    color="primary"
                                    formControlName="insurance"
                                    (change)="handleInsuranceChange($event)">
          <span class="checkbox-label"
                *ngIf="strategy">
            {{ strategy?.name + ' hinzufügen?'}}
          </span>
                      <span class="checkbox-label"
                            *ngIf="!strategy">
            Versicherung hinzufügen?
          </span><br>
                  </mat-slide-toggle>
              </div>
              <div class="input">
                  <mat-form-field appearance="outline">
                      <mat-label>Monatliche Prämie</mat-label>
                      <input id="input_insurance-fee"
                             matInput
                             formControlName="insurance_fee"
                             l7InputRef
                             (debounceKeyDown)="onCalculate($event)"
                             [placeholder]="'Monatliche Prämie'"
                             currencyMask
                             l7MarkAsTouched
                             [readOnly]="strategy?.name === '100pro'">
                      <mat-error *ngIf="internalForm.get('insurance_fee').errors">
                          {{ strategy?.getErrorMessage() }}
                      </mat-error>
                  </mat-form-field>
              </div>
          </div>
          <div class="input-container"
               *ngIf="showProMilleField">
              <div class="slide-toggle">

              </div>
              <div class="input">
                  <mat-form-field appearance="outline">
                      <mat-label>{{'calculation.pro_mille_placeholder' | translate}}</mat-label>
                      <input id="input_insurance-pro-mille"
                             matInput
                             formControlName="insurance_pro_mille"
                             [placeholder]="'calculation.pro_mille_placeholder' | translate"
                             l7InputRef
                             currencyMask
                             [l7Min]="0"
                             [l7Max]="100"
                             [options]="{suffix: ' ‰', precision: 4}">
                  </mat-form-field>
              </div>
          </div>
      </ng-container>
  </ng-container>

  <!-- MIETKAUF or MIETKAUF Netto-->
  <ng-container *ngIf="_calculation?.contract_type === contractTypes.Mietkauf || _calculation?.contract_type === contractTypes.Mkn">
      <div fxLayout="column">
          <h3 class="checkbox-label" *ngIf="_calculation?.contract_type === contractTypes.Mkn">
              {{ 'calculation.calculatorFeeForm.addStrategyMkn' | translate }}
          </h3>
          <input type="hidden"
                 formControlName="insurance"
                 l7InputRef
                 id="input_insurance">
          <div fxLayout="column" fxLayoutGap="24px">
              <div fxLayout="column">
                  <mat-form-field class="block select-insurance" appearance="outline" [fxFlex]="'0 1 calc(' + 100 + '%' + ' - 24px)'" [fxFlex.lt-md]="100">
                      <mat-label>{{'calculation.insurance_or_100_pro' | translate}}</mat-label>
                      <mat-select id="select_insurance-type"
                                  (selectionChange)="handleInsuranceSelection($event)"
                                  formControlName="insurance_type">
                          <mat-option *ngFor="let insurance of insuranceTypes; let i = index"
                                      [value]="insurance.value"
                                      [disabled]="insuranceTypeDisabled(insurance.value)">
                              {{ insurance.translation_key | translate | uppercase }}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
                  <div fxLayout="row" fxLayoutGap="16px">
                      <mat-form-field appearance="outline" [fxFlex]="'0 1 calc(' + 100 / 1.5 + '%' + ' - 16px)'" [fxFlex.lt-md]="100">
                          <mat-label>{{'calculation.month_rate_placeholder' | translate}}</mat-label>
                          <input formControlName="insurance_fee"
                                 matInput
                                 l7InputRef
                                 l7MarkAsTouched
                                 (debounceKeyDown)="onCalculate($event)"
                                 placeholder="{{'calculation.month_rate_placeholder' | translate}}"
                                 id="input_insurance-fee-hire-purchase"
                                 currencyMask
                                 [readOnly]="internalForm.getRawValue().insurance_type === 'PRO100'">
                          <mat-error *ngIf="internalForm.get('insurance_fee').errors">
                              {{ strategy?.getErrorMessage() }}
                          </mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline" *ngIf="showProMilleField" [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'" [fxFlex.lt-md]="100">
                          <mat-label>{{'calculation.pro_mille_placeholder' | translate}}</mat-label>
                          <input id="input_insurance-pro-mille-hire-purchase"
                                 formControlName="insurance_pro_mille"
                                 [placeholder]="'calculation.pro_mille_placeholder' | translate"
                                 matInput
                                 l7InputRef
                                 currencyMask
                                 [l7Min]="0"
                                 [l7Max]="100"
                                 [options]="{suffix: ' ‰', precision: 4}">
                      </mat-form-field>
                  </div>

              </div>
              <div fxLayout="row" fxLayoutGap="16px">
                  <mat-form-field appearance="outline" [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 16px)'" [fxFlex.lt-md]="100">
                      <mat-label>{{'calculation.total_claim' | translate}}</mat-label>
                      <input formControlName="total_insurance_instalments"
                             matInput
                             l7InputRef
                             [placeholder]="'calculation.total_claim' | translate"
                             id="input_insurance-total-claim"
                             currencyMask>
                  </mat-form-field>
                  <mat-form-field appearance="outline" [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 16px)'" [fxFlex.lt-md]="100">
                      <mat-label>{{'calculation.total_claim_taxes' | translate}}</mat-label>
                      <input id="input_insurance-total-claim-taxes"
                             *ngIf="_calculation?.contract_type !== contractTypes.Mkn || internalForm.getRawValue().insurance_type !== 'PRO100'"
                             formControlName="total_insurance_instalments_vat"
                             [placeholder]="'calculation.total_claim_taxes' | translate"
                             matInput
                             l7InputRef
                             currencyMask>
                      <input id="input_insurance-total-claim-taxes-mkn"
                             *ngIf="_calculation?.contract_type === contractTypes.Mkn && internalForm.getRawValue().insurance_type === 'PRO100'"
                             [placeholder]="'calculation.total_claim_taxes' | translate"
                             [value]="'Nettobetrag: keine USt.'"
                             [disabled]="true"
                             matInput
                             l7InputRef>
                  </mat-form-field>
              </div>
          </div>
      </div>
  </ng-container>
      <ng-container *ngIf="view === 'QUOTE_DETAIL'">
          <div class="input-container" fxLayout="column">
              <h3 class="checkbox-label" *ngIf="_calculation?.contract_type === contractTypes.Mietkauf">
                  {{ ('calculation.calculatorFeeForm.processingFee' | translate) + '?' }}
              </h3><br>
              <h3 class="checkbox-label" *ngIf="_calculation?.contract_type === contractTypes.Mkn">
                  {{ ('calculation.calculatorFeeForm.processingFee' | translate) }}
              </h3><br>
              <div fxLayout="row">
                  <div fxFlex="10" class="slide-toggle">
                      <mat-slide-toggle labelPosition="before"
                                        (change)="onCalculate($event)"
                                        name="handling_fee"
                                        color="primary"
                                        formControlName="handling_fee">
                      </mat-slide-toggle>
                  </div>
                  <div fxFlex="90">
                      <mat-form-field class="input" appearance="outline" (focusout)="showWarningForHandlingFee=false" (focusin)="showWarningForHandlingFee=true">
                          <mat-label>{{'calculation.calculatorFeeForm.processingFee'| translate }}</mat-label>
                          <input id="input_administration-fee-amount"
                                 formControlName="handling_fee_value"
                                 [placeholder]="'calculation.calculatorFeeForm.processingFee'| translate "
                                 matInput
                                 l7MarkAsTouched
                                 l7InputRef
                                 currencyMask>
                          <mat-icon matSuffix [fontSet]="'l7'"
                                    [fontIcon]="'Alert_Circle'"
                                    *ngIf="internalForm.controls.handling_fee_value.value > 500 && showWarningForHandlingFee">
                          </mat-icon>
                          <mat-hint *ngIf="internalForm.controls.handling_fee_value.value > 500 && showWarningForHandlingFee">
                              {{ 'calculation.handling_fee_warning' | translate }}
                          </mat-hint>
                      </mat-form-field>
                  </div>
              </div>
          </div>
      </ng-container>
      <ng-container *ngIf="view !== 'QUOTE_DETAIL'">
          <div class="input-container" fxLayout="column">
              <div class="slide-toggle" [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 16px)'" [fxFlex.lt-md]="100">
                  <mat-slide-toggle labelPosition="before"
                                    (change)="onCalculate($event)"
                                    name="handling_fee"
                                    color="primary"
                                    formControlName="handling_fee">
                      <span class="checkbox-label">{{ ('calculation.calculatorFeeForm.processingFee' | translate) + ' ?' }}</span>
                  </mat-slide-toggle>
              </div>
              <div  [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 16px)'" [fxFlex.lt-md]="100">
                  <mat-form-field appearance="outline" (focusout)="showWarningForHandlingFee=false" (focusin)="showWarningForHandlingFee=true">
                      <mat-label>{{ 'calculation.calculatorFeeForm.processingFee'| translate }}</mat-label>
                      <input id="input_administration-fee-amount"
                             formControlName="handling_fee_value"
                             [placeholder]="'calculation.calculatorFeeForm.processingFee'| translate "
                             matInput
                             l7MarkAsTouched
                             l7InputRef
                             currencyMask>
                      <mat-icon matSuffix [fontSet]="'l7'"
                                [fontIcon]="'Alert_Circle'"
                                *ngIf="internalForm.controls.handling_fee_value.value > 500 && showWarningForHandlingFee">
                      </mat-icon>
                      <mat-hint *ngIf="internalForm.controls.handling_fee_value.value > 500 && showWarningForHandlingFee">
                          {{ 'calculation.handling_fee_warning' | translate }}
                      </mat-hint>
                  </mat-form-field>
              </div>
          </div>


      </ng-container>
</form>
